/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/codemirror@5.65.19/addon/search/search.js
 * - /npm/codemirror@5.65.19/addon/search/matchesonscrollbar.js
 * - /npm/codemirror@5.65.19/addon/search/searchcursor.js
 * - /npm/codemirror@5.65.19/addon/search/jump-to-line.js
 * - /npm/codemirror@5.65.19/addon/scroll/annotatescrollbar.js
 * - /npm/codemirror@5.65.19/addon/edit/matchbrackets.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
